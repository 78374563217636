import styled from "styled-components";
import { muiTheme } from "../../../lib/Theme";

export const HeroText = styled.h1`
  font-size: 5.2rem;
  font-weight: 900;
  text-align: center;
  color: white;
  text-transform: uppercase;

  ${muiTheme.breakpoints.down("sm")} {
    font-size: 4.2rem;
  }
`;

import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export const useHeroImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query HeroImage {
      file(relativePath: { eq: "images/home/hero/test.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return file.childImageSharp.fluid;
};

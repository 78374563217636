import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { useHeroImage } from "./hooks/useHeroImage";
import { Header } from "../../Shared/Layout/components/Header/Header";
import { HeroText } from "../../Shared/HeroText/HeroText";
import { useTranslation } from "react-i18next";
import GatsbyLink from "gatsby-link";
import { routes } from "../../../lib/routes";
import { Container } from "@material-ui/core";
import { muiTheme } from "../../../lib/Theme";
import { rgba } from "polished";

export interface HeroSectionProps {
  className?: string;
}

export const CtaButton = styled(GatsbyLink)`
  padding: 15px 30px;
  text-transform: uppercase;
  background: ${props => props.theme.color.primary};
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border: 4px solid ${props => props.theme.color.primary};
  font-weight: 700;

  &:hover {
    background: ${rgba("black", 0.55)};
    border-color: white;
  }
`;

export const StyledHeroImage = styled(BackgroundImage)`
  &::before,
  &::after {
    filter: brightness(0.77);
  }
`;

const HeroSectionInner = (props: HeroSectionProps) => {
  const heroImage = useHeroImage();
  const { t } = useTranslation("home");
  return (
    <StyledHeroImage fluid={heroImage}>
      <Header dark />

      <Container>
        <section className={props.className}>
          <HeroText>{t("hero.text")}</HeroText>
          <CtaButton to={routes.services.to}>{t("hero.cta")}</CtaButton>
        </section>
      </Container>
    </StyledHeroImage>
  );
};

export const HeroSection = styled(HeroSectionInner)`
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  overflow-x: hidden;
  ${HeroText} {
    margin-bottom: 30px;

    ${muiTheme.breakpoints.down("sm")} {
      width: 100%;
    }
  }
`;
